import PhotoSwipe from 'photoswipe';
import PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default';
import 'photoswipe/dist/photoswipe.css';
import 'photoswipe/dist/default-skin/default-skin.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './main.scss';
const photos = require('../photos.json');

const items = photos.map((photo: any) => ({
    ...photo,
    src: require(`./photos/${photo.src}`), 
}));

const openGallery = (event: MouseEvent) => {
    event.preventDefault();
    const pswpElement = document.querySelectorAll('.pswp')[0]! as HTMLElement;
    const target: HTMLAnchorElement = event.target! as any;
    const gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, items, {
        // optionName: 'option value'
        // for example:
        index: +target.href.split('#')[1], // start at first slide
    });
    gallery.init();
}

const init = () => {
    document.getElementById('openGallery')!.addEventListener('click', openGallery);

    const contactEmailLink = document.getElementById('contact-email')!;
    const contactEmail = 'edina.robovics' + '@yahoo.com';
    contactEmailLink.setAttribute('href', `mailto:${contactEmail}`);
    contactEmailLink.innerText = contactEmail;
};

if (document.readyState === "loading") {  // Loading hasn't finished yet
    document.addEventListener("DOMContentLoaded", init);
} else {  // `DOMContentLoaded` has already fired
    init();
}